import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/content-analysis/one.png';
import picture1 from '../../../images/tools/content-analysis/two.png';

const Info = () => (
  <div className="word-count-info row mt-0 mx-0 mb-3">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Improve content SEO performance and engagement metrics by optimizing your content</h4>
      <div className="mb-2">
        Keyword density and semantic variations help search engines understand the topic and relevance of your content
        and can have an impact on your search engine rankings.
      </div>
      <div className="mb-2">
        Mention a keyword too many times and you could trigger an over-optimization penalty, instead aim to cover the
        topic via different terms users may use to find your product or service.
      </div>
      <div className="mb-2">
        Our content analysis tool performs an in-depth check of non-common keywords and all keywords to help you
        understand the keyword frequency and density in your content.
      </div>
      <div className="mb-4">
        We also provide word count, letter count, and reading time to help you optimize your content for optimal
        engagement and topic coverage.
      </div>

      <div>
        <Button className="px-0" onClick={() => navigate('/registration')}>
          Sign up for Hexomatic account to increase your limits
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture} alt="Grammar &amp; spelling page audit" />
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-3">
      <img src={picture1} alt="Grammar &amp; spelling page audit" />
    </div>
    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">Perform comprehensive site-wide content audits with Hexomatic</h4>
      <div className="mb-2">
        Upgrade to a paid Hexomatic plan to access site-wide content metrics and advanced research audits to evaluate
        and optimize your landing pages for optimal SEO performance.
      </div>
      <div className="mb-4">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready-made automations to
        perform content, spelling, grammar, or accessibility audits in minutes and delegate time-consuming sales,
        marketing, or research tasks.
      </div>
      <div>
        <Button onClick={() => navigate('/tools/')}>Click here to find out more</Button>
      </div>
    </div>
  </div>
);

export default Info;

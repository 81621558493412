import React, {FC} from 'react';
import './styles.scss';

interface CustomCardProps {
  title: string;
  nonCommonWordsCount: number[];
  nonCommonWords: string[];
}

const CustomCard: FC<CustomCardProps> = ({title, nonCommonWordsCount, nonCommonWords}) => {
  return (
    <div className="custom-card-wrapper">
      <div className="custom-card-header">
        <h5 className="title">{title}</h5>
        <div className="custom-br" />

        <div className="stats-header">
          <span className="title-secondary">Keywords</span>
          <span className="title-secondary">Quantity</span>
        </div>
      </div>

      <div className="custom-card-body">
        <div>
          {nonCommonWords.map((el: string, i: number) => (
            <p key={i} className="word">
              {el}
            </p>
          ))}
        </div>
        <div>
          {nonCommonWordsCount.map((el: number, i: number) => (
            <p key={i} className="count">
              {el}
            </p>
          ))}
        </div>

        {/* <span className="count">{el.value}</span> */}
      </div>
    </div>
  );
};

export default CustomCard;

import React, {FC} from 'react';
import './styles.scss';

interface MiniCardTypes {
  title: string;
}

const App: FC<MiniCardTypes> = ({title}) => {
  return (
    <div className="mini-card-wrapper">
      <span className="round-dot mr-1"></span> <span>{title}</span>
    </div>
  );
};

export default App;

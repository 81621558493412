import React, { FC } from "react";
import { nothingToShow } from "../../images/svgIcons";
import "./styles.scss";

interface INothingToShow {
  text?: string;
}

const NothingToShow: FC<INothingToShow> = ({ text }) => {
  return (
    <div className="with-center">
      <span>{nothingToShow}</span>
      <span className="empty">Nothing To Show! </span>
      <span className="empty-list">{text || "Your list is empty."}</span>
    </div>
  );
};

export default NothingToShow;

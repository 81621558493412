import React, {FC} from 'react';
import {Link} from 'gatsby';

import {makeValidHref} from '../../../helpers';
import NothingToShow from '../../nothingToShow';

import CustomCard from './custom-card';
import MiniCard from './mini-card';

import './styles.scss';

interface IAppProps {
  data: any;
  url: any;
}

const App: FC<IAppProps> = props => {
  if (props.data?._non_common_words_count[0] === 'Not found.') {
    return (
      <div className="mb-5 w-100 d-flex justify-content-center">
        <NothingToShow />
      </div>
    );
  }
  return (
    <div className="w-100">
      {props.url && (
        <div className="d-flex m-auto pb-2" style={{maxWidth: '741px', width: '90%'}}>
          <div>
            <Link to="/tools/history/#word-count">← Back to history</Link>
          </div>
          <div className="ml-auto" style={{maxWidth: 'calc(100% - 150px)', whiteSpace: 'nowrap', display: 'flex'}}>
            <div className="mr-1">Result for</div>
            <a
              title={props.url}
              href={makeValidHref(props.url)}
              target="_blank"
              className="text-truncate"
              style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
              rel='noopener'
            >
              {props.url}
            </a>
          </div>
        </div>
      )}
      <div className="result-wrapper">
        <div className="main-wrapper">
          <div className="m-card-wrapper">
            <MiniCard title={`${props.data?._word_count[0]} words found`} />
            <MiniCard title={`${props.data?._character_count[0]} letters found`} />
            <MiniCard title={props.data?._read_time_text[0] || 'not found'} />
          </div>

          <div className="result-card-wrapper">
            <CustomCard
              title="Non-Common Keywords"
              nonCommonWordsCount={props.data?._non_common_words_count}
              nonCommonWords={props.data?._non_common_words}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;

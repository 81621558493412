import React, {FC} from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import GetWordsCountWrapper from '../../../components/tools-components/word-count';

const WordCount: FC = () => (
  <Layout>
    <PageHero
      title="Analyze any page for on-page content metrics"
      titleSmall
      subtitle="Check keyword density, word count, reading time, and letter counts for any web page in seconds."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <GetWordsCountWrapper />
    </Section>{' '}
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 text-center">Content analysis FAQ</h4>
        <FAQItem
          title="How does a content analysis tool work?"
          text={
            <p>
              Our Hexomatic content analysis tool enables checking web pages for keyword density, word count, reading
              time, and letter counts on autopilot.
            </p>
          }
        />
        <FAQItem
          title="Why should I use a word count tool?"
          text={
            <p>
              Word count tools give you a good impression of the textual quality of your website although you are unable
              to determine the content quality using only a word counter tool. However, crucial web pages must contain
              content with the necessary number of high-ranking keywords, keyword variations, etc. Word counter tool can
              be ideal for that. Moreover, it allows checking the percentage of anchor text words on a given web page.
            </p>
          }
        />
        <FAQItem
          title="What is keyword density?"
          text={
            <p>
              Keyword density or keyword frequency is considered the percentage of times a keyword or a keyword phrase
              is displayed on a web page compared to the overall number of words on that web page. It is a pretty good
              indicator to determine whether a web page is relevant to a specified keyword or phrase.
            </p>
          }
        />
        <FAQItem
          title="How important is keyword density for SEO?"
          text={
            <>
              <p>
                Whilst content quality is kind, keyword density can play a role in ensuring your content fits a users’
                search query helping search engines find the best-matching web pages. For matching your web page with a
                certain query, it is necessary for Google to grasp what your web page is about. This is why it is
                important to use keywords or phrases you wish to rank for in your web page content.
              </p>
              <p>
                However, it is recommended to avoid using the same keywords or keyphrases too often as it can negatively
                affect your search engine ranking and trigger over optimization penalties. Using a keyword density
                checker tool will help you easily assess whether your web page contains too many duplicate keywords that
                may harm your website ranking.
              </p>
            </>
          }
        />
        <FAQItem
          title="What is the ideal keyword density?"
          text={
            <p>
              There is no exact answer to this question. It mainly depends on the content type. However, it is
              recommended to make your content look more natural to be perceived positively both by Google and your end
              users.
            </p>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default WordCount;
